import { AxiosResponse } from 'axios';
import { API } from 'config';
import * as CONSTANTS from 'config/constants';
import {
  APP_VERSION_TYPE,
  BADGES_DETAIL_TYPE,
  DataListResponse,
  GET_APP_VERSION_LIST_PARAMS_REQUEST,
  GET_REGIONS_PARAMS_REQUEST,
  REGION_DETAIL_TYPE,
  SETTING_CONFIGS,
  UPDATE_APP_VERSION_BODY,
  UPDATE_BADGE_BODY,
  UPDATE_COUNTRIES_BODY,
  UPDATE_MANY_COUNTRY_BODY,
  UPDATE_REGION_BODY,
  VERIFY_BODY_REQUEST,
} from 'models';
import { AppAPIInstance } from 'services';

//REGION

export const getRegionListApi = async (params?: GET_REGIONS_PARAMS_REQUEST) => {
  return await AppAPIInstance.get(API.REGIONS.DEFAULT, {
    params,
  });
};

export const getRegionDetailApi = async (
  id: string
): Promise<AxiosResponse<{ data: REGION_DETAIL_TYPE }>> => {
  return await AppAPIInstance.get(`${API.REGIONS.DEFAULT}/${id}`);
};

export const editRegionsApi = async (id: string, data?: UPDATE_REGION_BODY) => {
  return await AppAPIInstance.put(`${API.REGIONS.DEFAULT}/${id}`, data);
};

export const createRegionsApi = async (data?: UPDATE_REGION_BODY) => {
  return await AppAPIInstance.post(API.REGIONS.DEFAULT, data);
};

export const deleteRegionsApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.REGIONS.DEFAULT}/${id}`);
};

export const deleteManyRegionApi = async (ids: string[]) => {
  return await AppAPIInstance.delete(API.REGIONS.DEFAULT, {
    data: {
      ids,
    },
  });
};

//BADGES

export const getBadgeListApi = async () => {
  return await AppAPIInstance.get(API.BADGES.DEFAULT);
};

export const deleteBadgesApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.BADGES.DEFAULT}/${id}`);
};

export const getBadgesDetailApi = async (
  id: string
): Promise<AxiosResponse<{ data: BADGES_DETAIL_TYPE }>> => {
  return await AppAPIInstance.get(`${API.BADGES.DEFAULT}/${id}`);
};

export const editBadgesApi = async (id: string, data?: UPDATE_BADGE_BODY) => {
  return await AppAPIInstance.put(`${API.BADGES.DEFAULT}/${id}`, data);
};

export const createBadgesApi = async (data?: UPDATE_BADGE_BODY) => {
  return await AppAPIInstance.post(API.BADGES.DEFAULT, data);
};

export const deleteManyBadgesApi = async (ids: string[]) => {
  return await AppAPIInstance.delete(API.BADGES.DEFAULT, {
    data: {
      ids,
    },
  });
};

//VERSION
export const getAppVersionListApi = async (
  params?: GET_APP_VERSION_LIST_PARAMS_REQUEST
): Promise<AxiosResponse<{ data: DataListResponse<APP_VERSION_TYPE> }>> => {
  return await AppAPIInstance.get(API.APP_VERSION.DEFAULT, {
    params,
  });
};

export const getAppVersionDetailApi = async (
  id: string
): Promise<AxiosResponse<{ data: APP_VERSION_TYPE }>> => {
  return await AppAPIInstance.get(`${API.APP_VERSION.DEFAULT}/${id}`);
};

export const updateAppVersionApi = async (
  id: string,
  data: UPDATE_APP_VERSION_BODY
) => {
  return await AppAPIInstance.put(`${API.APP_VERSION.DEFAULT}/${id}`, data);
};

export const getSettingsGeneralApi = async (): Promise<
  AxiosResponse<{ data: { [key: string]: any } }>
> => {
  return await AppAPIInstance.get(API.SETTINGS.GENERAL.DEFAULT);
};

export const getSettingsJobsAndSittersApi = async (): Promise<
  AxiosResponse<{ data: { [key: string]: any } }>
> => {
  return await AppAPIInstance.get(API.SETTINGS.JOBS_AND_SITTERS.DEFAULT);
};

export const getSettingsTimezonesApi = async (): Promise<
  AxiosResponse<{ data: string[] }>
> => {
  return await AppAPIInstance.get(API.SETTINGS.TIMEZONES.DEFAULT);
};

export const updateSettingsApi = async (data: { [key: string]: any }) => {
  return await AppAPIInstance.put(API.SETTINGS.DEFAULT, data);
};

export const getSettingConfigsApi = async () => {
  return await AppAPIInstance.get<{ data: SETTING_CONFIGS }>(
    API.SETTINGS.CONFIGS.DEFAULT
  );
};

export const getSettingsCountriesListApi = async (params?: any) => {
  return await AppAPIInstance.get(API.COUNTRIES.DEFAULT, {
    params,
  });
};

export const updateCountriesApi = async (
  id: string,
  data: UPDATE_COUNTRIES_BODY
) => {
  return await AppAPIInstance.put(`${API.COUNTRIES.DEFAULT}/${id}`, data);
};

export const removeCountriesFromBlackListApi = async (id: any) => {
  return await AppAPIInstance.delete(`${API.COUNTRIES.DEFAULT}/${id}`);
};

export const updateManyCountryApi = async (data: UPDATE_MANY_COUNTRY_BODY) => {
  return await AppAPIInstance.put(API.COUNTRIES.DEFAULT, data);
};

export const getGenerateTokensApi = async (): Promise<AxiosResponse<any>> => {
  return await AppAPIInstance.get(API._2FA.DEFAULT);
};

export const update2FAApi = async (data: {
  token: string;
  enable_2fa: boolean;
}) => {
  return await AppAPIInstance.patch(API._2FA.UPDATE, data);
};

export const verify2FAApi = async (data: VERIFY_BODY_REQUEST) => {
  const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN_OTP);
  return AppAPIInstance.post(API._2FA.VERIFY, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((rs) => {
    localStorage.removeItem(CONSTANTS.ACCESS_TOKEN_OTP);
    return rs;
  });
};
