import { METADATA_TYPE } from './accounts';
import {
  CHAT_KEYWORD_TRACKING,
  GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST,
} from './chat';
import { OptionItem } from './global';
import { CERTIFICATE_TYPE } from './nanny';
import { REASON_TYPE } from './reason';

export interface GET_REGIONS_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  offset?: number;
  order?: string;
  keyword?: string;
}

export interface REGIONS_TYPE {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface REGIONS_BODY_RESULT {
  records: REGIONS_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_REGIONS_PARAMS_REQUEST | null;
}

export interface REGION_DETAIL_TYPE {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface UPDATE_REGION_BODY {
  name: string;
}

export interface GET_REASONS_LIST_PARAMS {
  page?: number;
  limit?: number;
  offset?: number;
  order?: string;
  keyword?: string;
}

export interface REASONS_LIST_STATE {
  records: REASON_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_REASONS_LIST_PARAMS | null;
}

export interface APP_VERSION_LIST_STATE {
  records: APP_VERSION_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_APP_VERSION_LIST_PARAMS_REQUEST | null;
}

export interface CHAT_KEYWORD_TRACKING_LIST_STATE {
  records: CHAT_KEYWORD_TRACKING[];
  metadata: METADATA_TYPE;
  params?: GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST | null;
}

//BADGES
export interface BADGES_TYPE {
  id: string;
  title: string;
  icon: string;
  createdAt: string;
  updatedAt: string;
}

export interface BADGES_DETAIL_TYPE {
  id: string;
  title: string;
  icon: string;
  no_of_bookings_needed: number | null;
  no_of_hours_worked: number | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface BADGE_LIST_STATE {
  records: BADGES_TYPE[];
  metadata: METADATA_TYPE;
  params?: any;
}
export interface UPDATE_BADGE_BODY {
  title: string;
  icon: string;
  no_of_bookings_needed?: number | null;
  no_of_hours_worked?: number | null;
}

export enum APP_VERSION_FORCE_UPDATERO {
  force = 'force',
  recommend = 'recommend',
  not_require = 'not_require',
}

export const appVersionForceUpdateroOptions: OptionItem[] = [
  {
    id: APP_VERSION_FORCE_UPDATERO.force,
    label: APP_VERSION_FORCE_UPDATERO.force,
  },
  {
    id: APP_VERSION_FORCE_UPDATERO.recommend,
    label: APP_VERSION_FORCE_UPDATERO.recommend,
  },
  {
    id: APP_VERSION_FORCE_UPDATERO.not_require,
    label: APP_VERSION_FORCE_UPDATERO.not_require,
  },
];

export interface APP_VERSION_TYPE {
  id: string;
  flatform: string;
  version: string;
  force_updatero: string;
  createdAt: string;
  updatedAt: string;
}

export interface UPDATE_APP_VERSION_BODY {
  version?: string;
  force_updatero?: string;
}

export interface GET_APP_VERSION_LIST_PARAMS_REQUEST {
  limit?: number;
  page?: number;
  offset?: number;
  flatform?: string;
  version?: string;
  force_updatero?: string;
}

export enum SETTING_KEY {
  admin_email = 'admin_email',
  timezone = 'timezone',
  job_completion_timeout = 'job_completion_timeout',
  aunty_commission_rate = 'aunty_commission_rate',
  star_sitter_quota = 'star_sitter_quota',
  edit_review_timeout = 'edit_review_timeout',
  sitter_review_timeout = 'sitter_review_timeout',
}

export enum SETTING_VALUE_TYPE {
  number = 'number',
  string = 'string',
}

export interface SETTING_ITEM_TYPE {
  id: string;
  title: string;
  type: string;
  key: string;
  value: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface GET_COUNTRIES_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  offset?: number;
  order?: string;
  keyword?: string;
  name?: string;
  code?: string;
  phone_code?: string;
  is_black_list?: boolean;
  sort_column?: string;
  sort_direction?: string;
}

export interface COUNTRIES_TYPE {
  id: number;
  name: string;
  code: string;
  phone_code: string;
  is_black_list: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface COUNTRY_LIST_STATE {
  records: COUNTRIES_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_COUNTRIES_PARAMS_REQUEST | null;
}

export interface UPDATE_COUNTRIES_BODY {
  is_black_list: boolean;
}

export interface UPDATE_MANY_COUNTRY_BODY {
  ids: string[];
  is_black_list: boolean;
}

export interface SETTING_CONFIGS {
  [SETTING_KEY.star_sitter_quota]: number;
  [SETTING_KEY.sitter_review_timeout]: number;
  [SETTING_KEY.aunty_commission_rate]: number;
  [SETTING_KEY.admin_email]: string;
  [SETTING_KEY.timezone]: string;
  [SETTING_KEY.edit_review_timeout]: number;
  [SETTING_KEY.job_completion_timeout]: number;
}

//PROMOS
export interface GET_PROMOS_LIST_PARAMS {
  page?: number;
  limit?: number;
  offset?: number;
  order?: string;
  search?: string;
  status?: string;
}

export interface PROMOS_TYPE {
  aunty_promo_title: string;
  aunty_promocode: string;
  createdAt: string;
  deletedAt: null | string;
  eber_hidden_code: string;
  id: string;
  status: string;
  updatedAt: string;
}

export interface PROMOS_LIST_STATE {
  records: PROMOS_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_PROMOS_LIST_PARAMS | null;
}

export enum PROMOS_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const PROMOS_STATUSES: OptionItem[] = [
  {
    id: PROMOS_STATUS.ACTIVE,
    name: 'Active',
  },
  {
    id: PROMOS_STATUS.INACTIVE,
    name: 'Inactive',
  },
];

export const promosStatusColor: { [key: string]: string } = {
  [PROMOS_STATUS.ACTIVE]: '#34C759',
  [PROMOS_STATUS.INACTIVE]: '#FF3B30',
};

export interface GET_CERTIFICATES_PARAMS_REQUEST {
  pageNum?: number;
  pageSize?: number;
  nannyId?: string;
  includeDeleted?: boolean;
  keyword?: string;
}
export interface CERTIFICATES_BODY_RESULT {
  records: CERTIFICATE_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_CERTIFICATES_PARAMS_REQUEST | null;
}

export interface GENERATE_QRCODE_BODY_RESULT {
  base32: string;
  otpauth_url: string;
}
