import {
  AppBar,
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { Layout } from 'components';
import { drawerWidth } from 'config';
import React, { useState } from 'react';
import Accounts from './Accounts';
import Badges from './Badges';
import Certificates from './Certificates';
import ChatKeywordTracking from './ChatKeywordTracking';
import General from './General';
import JobsAndSitters from './JobsAndSitters';
import ManageAppVersion from './ManageAppVersion';
import OTPBlacklist from './OTPBlacklist';
import ReasonForReportingUser from './ReasonForReportingUser';
import Regions from './Regions';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`sitter-detail-tabpanel-${index}`}
      aria-labelledby={`sitter-detail-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `sitter-detail-tab-${index}`,
    'aria-controls': `sitter-detail-tabpanel-${index}`,
  };
}

const tabs: string[] = [
  'General',
  'Accounts',
  'Jobs & Sitters',
  'Reporting',
  'Badges',
  'Certificates',
  'Regions',
  'Manage App Version',
  'Chat Keyword Tracking',
  'OTP Blacklist',
];

const Settings = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Settings
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Paper elevation={0} sx={{ p: '16px' }}>
        <Grid
          container
          sx={{
            borderBottom: '1px solid  rgba(0, 0, 0, 0.08)',
            marginBottom: '16px',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChangeTabValue}
            aria-label="basic tabs example"
          >
            {tabs.map((item, index) => {
              return (
                <Tab
                  key={index}
                  label={item}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '125%',
                    letterSpacing: '0.1px',
                    color: '#959595',
                    textTransform: 'capitalize',
                  }}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </Grid>
        <TabPanel value={tabValue} index={0}>
          <General />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Accounts />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <JobsAndSitters />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <ReasonForReportingUser />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <Badges />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <Certificates />
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <Regions />
        </TabPanel>
        <TabPanel value={tabValue} index={7}>
          <ManageAppVersion />
        </TabPanel>
        <TabPanel value={tabValue} index={8}>
          <ChatKeywordTracking />
        </TabPanel>
        <TabPanel value={tabValue} index={9}>
          <OTPBlacklist />
        </TabPanel>
      </Paper>
    </Layout>
  );
};

export default Settings;
