import { HeadCell, REVIEWS_TYPE } from 'models';

export const headCells: readonly HeadCell<REVIEWS_TYPE>[] = [
  {
    id: 'Parent',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Parent Name',
  },
  {
    id: 'Parent',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Parent Email',
  },
  {
    id: 'Sitter',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Sitter Name',
  },
  {
    id: 'Sitter',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Sitter Email',
  },
  {
    id: 'Job',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Job Number',
  },
  {
    id: 'content',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Review Comments',
  },
  {
    id: 'rate',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Number of Stars',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Date & Time',
  },
];
