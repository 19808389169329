import { ToastOptions } from 'react-toastify';

export const API = {
  AUTH_USER: {
    REFRESH_TOKEN: '/admin/refresh-token',
    LOGIN: '/services/app/tokenauth/login-seller',
    PROFILE: '/services/seller/customers/get-profile',
  },
  ADMIN: {
    SR: {
      DEFAULT: '/admin/service-requests/',
      BY_ID: '/admin/service-requests/{id}',
      SITTERS: '/admin/service-requests/{id}/sitters',
      RATE_OFFER: '/admin/service-requests/booking/',
      REQUEST_FOR_PAYMENT:
        '/admin/service-requests/booking/{id}/request-for-payment',
      MAKE_PAYMENT: '/admin/booking/{id}/make-payment',
    },
    TRANSACTION: {
      DEFAULT: '/admin/transactions',
      TYPE: '/admin/transaction-types',
      DOC_REF_NO: '/admin/doc-ref-no',
      EXPORT: '/admin/transactions/export-csv',
    },
    PAYMENT: {
      DEFAULT: '/admin/payments',
      UPDATE_STATUS: '/admin/payments/:id/status',
      UPDATE_MANY_STATUS: '/admin/payments/many/status',
      EXPORT: '/admin/payments/export-csv',
      REMOVE_TXN: '/admin/payments/payout/transaction',
      ADD_TXN: '/admin/payments/payout/many-transaction',
      LIST_TXN: '/admin/payments/payout/add-transactions',
      GEN_PENDING: '/admin/payments/generate-pending-payout',
      PAYMENT_METHODS: '/payout-methods/',
    },
    JOB: {
      DEFAULT: '/admin/jobs',
      UPDATE_STATUS: '/admin/jobs/:id/status',
      EXPORT: '/admin/jobs/export-csv',
    },
    USER: {
      DEFAULT: '/admin/users',
      ALL: '/users',
    },
    PARENT: {
      NOTES: {
        DEFAULT: '/admin-notes',
      },
      EXPORT: '/admin/parents/export-csv',
      ADDRESS: {
        DEFAULT: '/admin/parents/:id/addresses',
      },
      CHILDREN: {
        DEFAULT: '/admin/parents/:id/children',
      },
    },
    SITTER: {
      DEFAULT: '/admin/sitters',
      EXPORT: '/admin/sitters/export-csv',
      BADGE: {
        DEFAULT: '/admin/sitters/badges',
      },
    },
    NANNY: {
      DEFAULT: '/admin/nanny',
      ALL: '/admin/nanny-list',
      ADD: '/admin/nanny/create-nanny',
      UPDATE: '/admin/nanny/update-profile',
      BULK_DELETE: '/admin/nanny/bulk-delete',
      BULK_APPROVE: '/admin/nanny/bulk-approve',
      UPLOAD_PICTURE: 'nanny/upload-profile-picture',
      STATIC_DATA: {
        NATIONALITY: 'staticdata/nationality',
        ETHNICITY: 'staticdata/ethnicity',
      },
      BOOKING: '/admin/nanny/booking',
      ACTIVITY: '/admin/activity',
      PICTURE: '/admin/nanny/image',
      TESTIMONIAL: '/admin/nanny/testimonial',
      CERTIFICATE: '/admin/nanny/certificate',
      CERTIFICATE_ASSOCIATION: '/admin/nanny/certificate-association',
    },
  },
  BOOKING: {
    DEFAULT: '/booking',
  },
  REGIONS: {
    DEFAULT: '/regions',
  },
  COUNTRIES: {
    DEFAULT: '/countries',
  },
  CHAT: {
    DEFAULT: '/chats',
    MESSAGES: {
      DEFAULT: '/chats/:id/message',
    },
    KEYWORD_TRACKING: {
      DEFAULT: '/chats/keyword-tracking',
    },
  },
  REVIEWS: {
    DEFAULT: '/reviews',
    CREATE: '/reviews/admin-create-review',
    ADMIN: '/reviews/by-admin',
    EXPORT: '/reviews/export/csv',
  },
  ONE_MAP: {
    DEFAULT: '/onemap/address/:postal_code',
  },
  REPORT: {
    DEFAULT: '/report',
    REASON: {
      DEFAULT: '/report/reasons',
    },
    UPLOAD_IMG: '/report/upload/image',
  },
  PROMOS: {
    DEFAULT: '/promocodes',
    DETAIL: '/promocodes/:id',
  },
  FLAG: {
    DEFAULT: '/user-flags',
    NOTIFY: '/user-flags/push-notification',
  },
  BADGES: {
    DEFAULT: '/badges',
  },
  APP_VERSION: {
    DEFAULT: '/app-version',
  },
  NANNY_BOOKING: {
    DEFAULT: 'admin/booking',
    BULK_DELETE: 'admin/booking/bulk-delete',
    BULK_CANCEL: 'admin/booking/bulk-cancel',
    NANNY: {
      LIST: 'admin/booking/nanny-availability',
      UPDATE: 'admin/nanny/booking/update-availability',
      SELECT: 'admin/nanny/booking/select-nanny',
    },
    ACTIVITY_LOG: {
      LIST: 'admin/booking/activity-log',
      ADD: 'admin/booking/add-activity',
      UPDATE: 'admin/booking/update-activity',
      DELETE: 'admin/booking/delete-activity',
    },
  },
  SETTINGS: {
    DEFAULT: '/settings',
    GENERAL: {
      DEFAULT: '/settings/general',
    },
    JOBS_AND_SITTERS: {
      DEFAULT: '/settings/jobs-and-sitters',
    },
    TIMEZONES: {
      DEFAULT: '/settings/timezones',
    },
    CONFIGS: {
      DEFAULT: '/settings/configs',
    },
  },

  DASHBOARD: {
    DEFAULT: '/dashboard',
    PARENTS: {
      DEFAULT: '/dashboard/getNumberOfParentsData',
    },
    SITTERS: {
      DEFAULT: '/dashboard/getNumberOfSittersData',
    },
    SERVICE_FEE: {
      DEFAULT: '/dashboard/getServicesFeeCharged',
    },
    HOURS: {
      DEFAULT: '/dashboard/getNumberOfHoursData',
    },
    TRANSACTIONS: {
      DEFAULT: '/dashboard/getNumberOfTransactionsData',
    },
    STATISTICS: {
      DEFAULT: '/dashboard/getSummaryStatistics',
    },
  },
  _2FA: {
    DEFAULT: '/2fa/generate-token',
    UPDATE: '/2fa/status',
    VERIFY: '/2fa/verify-token',
  },
};

export const patternEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const patternURL =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
// export const patternPassword =
//   /(?=^.{8,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!@#$%^&*()])([0-9a-zA-Z!@#$%^&*()])*$/;

export const patternPassword = /^.*/g;

export const drawerWidth = 253;

export const OPTIONS_DATA = [
  {
    label: 'Bulk Action',
    value: 'bulkAction',
  },
  {
    label: 'Option 2',
    value: 'option2',
  },
  {
    label: 'Option 3',
    value: 'option3',
  },
];

export const TOAST: { [key: string]: ToastOptions } = {
  error: {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
  success: {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
};

export const isRefreshing = 'isRefreshing';
export const ACCESS_TOKEN = 'ACCESS_TOKEN_APP';
export const ACCESS_TOKEN_OTP = 'ACCESS_TOKEN_OTP';
export const REFRESH_TOKEN = 'REFRESH_TOKEN_APP';
export const INIT_COUNTRY_CODE = { code: '+65', name: 'sg' };
export const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB in bytes
